import styled from 'styled-components';
import FillOutForm from '../components/FormGenerator.js';

const Home = () => {
  return (
    <Container>
      <br></br><br></br>
      <br />
      <div>
        <br /><br />
        <FillOutForm />
      </div>
      <br />
    </Container>
  )
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    > * {
        width: 100%;
    }
`;

export default Home;