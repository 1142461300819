
const cognitoDetails = (() => {
    const cognitoDomain = 'staff-auth.movember.com';
    const clientId = '3bo9r650vl0r2fifhl85eo1dra';
    const redirect_uri = encodeURIComponent("https://termsomatic.test.movember.com");
    const scopeList = ['aws.cognito.signin.user.admin', 'email', 'openid'];
    const scope = scopeList.join('+');
  
    const ssoLoginUrl =`https://${cognitoDomain}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirect_uri}`;
    const authenticateUrl = `https://${cognitoDomain}/oauth2/token?grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirect_uri}`;
  
    return {
      ssoLoginUrl,
      authenticateUrl
    };
})();

const SsoService = {
    login: () => {
        window.location.href = cognitoDetails.ssoLoginUrl;
    },

    authenticate: async (code) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST',`${cognitoDetails.authenticateUrl}&code=${code}`);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function () {
              if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                  const data = JSON.parse(xhr.response);
                  console.log(data);
                  localStorage.setItem('sso-accessToken', data.access_token);
                  localStorage.setItem('sso-idToken', data.id_token);
                  localStorage.setItem('sso-refreshToken', data.refresh_token);
                  localStorage.setItem('sso-tokenType', data.token_type);
                  localStorage.setItem('sso-expires_in', data.expires_in);
                  resolve(data); // Resolve the promise with the response data
                } else {
                  reject(new Error(`Request failed with status ${xhr.status}`)); // Reject the promise with an error
                }
              }
            };
            xhr.send();
        });
    },

    isAuthenticated: async () => {
        if (localStorage.getItem('sso-accessToken')) {
            return true;
        }

        console.log("Not Logged in");
        const currentFullURL = new URL(window.location.href);
        // Check if the 'param' parameter exists in the URL
        if (currentFullURL.searchParams.has('code')) {
          // Get the value of the 'param' parameter
          const paramValue = currentFullURL.searchParams.get('code');
          try {
            await SsoService.authenticate(paramValue);
            console.log('Authentication successful');
            window.location.href = '/';
            return true;
          } catch (error) {
            console.error('Authentication failed:', error);
            return false;
          }
        } else {
            return false;
        }
    },

    logout: () => {
        localStorage.removeItem('sso-accessToken');
        localStorage.removeItem('sso-idToken');
        localStorage.removeItem('sso-refreshToken');
        localStorage.removeItem('sso-tokenType');
        localStorage.removeItem('sso-expires_in');
        window.location.href = '/';
    }
};

export default SsoService;
