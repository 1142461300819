import React, { useState, useEffect } from 'react';
import '../css/popup.css';
import FileSaver from 'file-saver';
import { API_ENDPOINT } from '../constants';
const FillOutForm = () => {
  const [fileNames, setFileNames] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [apiData, setApiData] = useState(null);
  const [formData, setFormData] = useState({});
  const [validationError, setValidationError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleCountrySelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedCountry(selectedValue);
    console.log('Selected country:', selectedValue);

    setFormData({});
    setFormSubmitted(false);

    // Call the API to get templates based on selected country
    if (selectedValue !== '') {
      fetchTemplates(selectedValue);
    } else {
      setFileNames([]);
    }
  };

  const fetchTemplates = (country) => {
    const apiUrl = `${API_ENDPOINT}/contract-generator-s3?country=${country}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log('API response:', data);
        setFileNames(data); // Assuming the API returns an array of file names
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };

  useEffect(() => {
    if (apiData && apiData.data) {
      const formValues = {};
      apiData.data.forEach((value, index) => {
        const inputId = `textbox-${index}`;
        const promptContainsWord = value.toLowerCase().includes('country') && !value.toLowerCase().includes('url');
        if (promptContainsWord) {
          formValues[inputId] = selectedCountry;
        }
      });
      setFormData(formValues);
      checkFormValidity();
    }
  }, [apiData]);

  const handleTemplateSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    console.log('Selected template:', selectedValue);
    setApiData(null);
    setIsFormValid(!!selectedValue);
    setValidationError(false); 
  };

  const handleButtonClick = () => {
    if (selectedOption) {
      setIsLoading(true);
      const apiUrl = `${API_ENDPOINT}/contract-generator?template_key=${selectedOption}&country=${selectedCountry}`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          console.log('API response:', data);
          setApiData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('API error:', error);
          setIsLoading(false);
        });
    } else {
      console.log('No option selected');
    }
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
    checkFormValidity();
  };

  const checkFormValidity = () => {
    const emptyFields = apiData.data.some((value, index) => {
      const inputId = `textbox-${index}`;
      return !formData[inputId] || formData[inputId].trim() === '';
    });
    setIsFormValid(!emptyFields);
  };

  const handleGenerateClick = () => {
    const payload = {
      vars: {},
      templateKey: selectedOption,
      country: selectedCountry,
    };

    apiData.data.forEach((value, index) => {
      const fieldName = `[${value}]`;
      const inputId = `textbox-${index}`;
      payload.vars[fieldName] = formData[inputId];
    });

    const emptyFields = apiData.data.some((value, index) => {
      const inputId = `textbox-${index}`;
      return !formData[inputId] || formData[inputId].trim() === '';
    });

    if (emptyFields) {
      setValidationError(true);
    } else {
      setValidationError(false);

      setIsGenerating(true);

      fetch(`${API_ENDPOINT}/contract-generator`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Generate API call failed');
          }
        })
        .then((data) => {
          console.log('Generate API response:', data);
          const s3Key = data.data;

          // Call the API to retrieve the document with the 'key' query parameter
          fetch(`${API_ENDPOINT}/contract-generator-s3?key=${s3Key}`)
            .then((response) => {
              if (response.ok) {
                return response.blob();
              } else {
                throw new Error('Get document API call failed');
              }
            })
            .then((file) => {
              console.log('Get document API response');
              const decodedFileName = decodeURIComponent(s3Key); 
              FileSaver.saveAs(file, decodedFileName);
              console.log(`Downloaded document: ${decodedFileName}`);
            })
            .catch((error) => {
              console.error('Get document API error:', error);
            });

          setIsGenerating(false);
        })
        .catch((error) => {
          console.error('Generate API error:', error);
          setIsGenerating(false);
        });
    }
  };

  return (
    <div className="PopUp2">
      <br />
      <label htmlFor="countrySelect">Select your Country:</label>
      <br />
      <select id="countrySelect" onChange={handleCountrySelect} style={{ maxWidth: '100%' }}>
        {/* make these dynamic with all folder names */}
        <option value="">Select</option>
        <option value="Australia">Australia</option>
        <option value="Canada">Canada</option>
        <option value="USA">USA</option>
        <option value="Ireland">Ireland</option>
        <option value="UK">UK</option>
      </select>
      <br />
      <br />
      <label htmlFor="templateSelect">Select a Template:</label>
      <br />
      <select id="templateSelect" onChange={handleTemplateSelect} style={{ maxWidth: '100%' }}>
        {fileNames.length > 0 ? (
          <>
            <option value="">Select</option>
            {fileNames.map((fileName, index) => (
              <option key={index} value={fileName}>
                {fileName}
              </option>
            ))}
          </>
        ) : (
          <option value="" disabled>
            No templates available
          </option>
        )}
      </select>
      <br />
      <br />
      <button onClick={handleButtonClick} disabled={!isFormValid}>
        Find Variables
      </button>

      {isLoading && <p>Fetching variables now...</p>}

      {apiData && !isGenerating && (
        <div id="variables" className="variables-container">
          <hr className="divider" />
          <br />

          <p>Please enter values into the following fields:</p>
          {apiData.data.map((value, index) => (
            <div key={index} className="field-container">
              <label htmlFor={`textbox-${index}`} className="field-label" style={{ maxWidth: '100%' }}>
                {value}:
              </label>
              <input
                name={value}
                id={`textbox-${index}`}
                type="text"
                className="field-input"
                onChange={handleInputChange}
                required
                style={{ maxWidth: '100%' }}
                value={formData[`textbox-${index}`] || ''} // Use the form data value
              />
            </div>
          ))}
          <br />

          <div className="button-container">
            <button onClick={handleGenerateClick}>
              Generate
            </button>
          </div>
          <br />
        </div>
      )}

      {validationError && (
        <p className="error-message">Please fill out all values before generating.</p>
      )}

      {isGenerating && <p>Document being generated...</p>}
    </div>
  );
};

export default FillOutForm;