import styled from 'styled-components';
import SsoService from '../services/sso';

const Login = () => {
    return (
        <Container>
            <Logo src="/logo.png" alt="logo"/>
            <Content>
                <Title>Termsomatic</Title>
                <LoginBtn onClick={() => SsoService.login()}>SSO Login</LoginBtn>
            </Content>
        </Container>
    )
};

const Container = styled.div`
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    width: 75px;
    margin: 30px;
`;

const Content = styled.div`
    border-radius: 16px;
    border: solid white;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
`

const Title = styled.h1`
    font-size: 1.5em;
    text-align: center;
`;

const LoginBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;
    min-width: 146px;
    margin: 12px 6px;
    border-radius: 24px;
    background: white;
    cursor: pointer;
    color: black;
`

export default Login;