/* header.js */

import React from "react";
import { Link } from "react-router-dom";

import '../css/header.css';
import SsoService from "../services/sso";

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <h1 className="headertext">Termsomatic</h1>
        </Link>
      </div>
      <div className="image-buttons-container">
        <div className="image-container">
          <img src="logo.png" alt="Logo" />
        </div>
        <div className="buttons">
          <Link to="/">
            <button onClick={() => SsoService.logout()}><span>logout</span></button>
          </Link>
          {/* <Link to="/about">
            <button><span>About</span></button>
          </Link> */}
        </div>
      </div>
    </header>
  );
}

export default Header;
