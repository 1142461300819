import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './pages/about.js';
import Home from './pages/index.js';
import Login from './pages/login.js';
import Header from './components/header';
import SsoService from './services/sso';

import { useState, useEffect } from 'react';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  useEffect(() => {
    const trySso = async () => {
      setIsAuthenticated(await SsoService.isAuthenticated());
    }
  
    trySso().catch(console.error);
  }, [])

  return (
    <>
        <Router>
            <Routes>
              {isAuthenticated 
                ? <>
                  <Route exact path='/' element={<Page name='home'/>} />
                  {/* <Route exact path='/about' element={<Page name='about'/>} /> */}
                </>
                : <Route path='*' element={<Login />}/>
              }
            </Routes>
        </Router>
    </>
  );
};

const Page = ({ name }) => {
  return (
    <>
      <Header/>
      {name === 'about' ? <About/> : <Home/>}
    </>
  );
}

export default App;
