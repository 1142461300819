
function About() {
  return (

  <div>
      <h1>About Page</h1>
      <p>Developed by Lachlan Malliaros.</p>
      <br></br>
      <p>For any assistance or any suggested improvments to the platform please attempt to contact me at:
        <br></br>lachlan.malliaros@gmail.com
      </p><br></br>
      <p>In the case of an urgent matter, if assistance is required imediatly, contact me at:
        <br></br>Ph: +61 450450989
      </p>
      
  </div>

  );
}


export default About;